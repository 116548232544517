import * as React from "react";
import { Avatar, Box, Button, Divider, Text } from "@chakra-ui/react";
import Background from "./components/background";
import Topbar from "./components/topbar";
import Footer from "./components/footer";

function App() {
    return (
        <Box width="100%" height="100vh">
            <Background />
            <Topbar />
            <Box 
                width="100%" 
                minHeight={{ base: "70vh", md: "70vh" }} 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                padding={{ base: "2rem", md: "10rem" }}
                paddingTop={{ base: "4rem", md: "10rem" }}
            >
                <Box 
                    width={{ base: "100%", md: "75%" }} 
                    height="100%" 
                    display="flex" 
                    flexDirection="column" 
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box 
                        display="flex" 
                        flexDirection={{ base: "column", md: "row" }} 
                        alignItems="center"
                        textAlign={{ base: "center", md: "left" }}
                        width="100%"
                    >
                        <Avatar 
                            size="2xl" 
                            name="Léo Baldachino" 
                            src="https://media.licdn.com/dms/image/D4E03AQGXqLcoIIHMlg/profile-displayphoto-shrink_200_200/0/1707690625812?e=1717632000&v=beta&t=GEEb3dOYwltQJL8ItUB1dxLpIjHrmwf7RKfydRcSDbY"
                            marginBottom={{ base: "1rem", md: "0" }}
                        />
                        <Text
                            fontSize={{ base: "4xl", md: "6xl" }}
                            fontFamily="'Reddit Mono', monospace"
                            textColor="white"
                            marginLeft={{ base: 0, md: "2rem" }}
                            marginRight={{ base: 0, md: "2rem" }}
                            bgGradient="linear(to-r, #474F7A, #81689D)"
                            bgClip="text"
                        >
                            <span style={{ color: "white" }}>Hi, I'm </span>
                            <span style={{ backgroundImage: "linear-gradient(to right, #474F7A, #81689D)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", color: "lightblue" }}>Léo Baldachino</span>
                            <span style={{ color: "white" }}>!</span>
                        </Text>
                    </Box>
                    <Text 
                        color="white" 
                        fontFamily="'Reddit Mono', monospace" 
                        fontSize={{ base: "1.2rem", md: "1.5rem" }} 
                        marginTop="1rem" 
                        lineHeight="2rem" 
                        textAlign={{ base: "center", md: "justify" }}
                    >
                        I'm a full-stack developer and a computer science student at <span style={{ color: "lightblue" }}>Epitech Mulhouse</span>. Currently in 4th year of the curriculum, I have a strong experience in web development, with proficiency in <span style={{color: '#81689D'}}>Cybersecurity</span> & <span style={{color: '#81689D'}}>DevOps</span>. I am currently living in
                        <Text as="span" bgGradient="linear(to-r, #FFD700, #FFD700, #FF0000, #FF0000, gray, gray)" bgClip="text"> Berlin </Text>
                        for my 4th year at the <span style={{ color: "lightblue" }}>Epitech</span> campus of Berlin, specializing in <span style={{ color: "lightblue" }}>video game development</span>.
                    </Text>
                </Box>
            </Box>
            <Box 
                width="100%" 
                height={{ base: "15vh", md: "10vh" }} 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                marginBottom={{ base: "10rem", md: "20rem" }}
            >
                <svg 
                    onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="white"
                    width="3rem" 
                    height="3rem"
                    cursor="pointer"
                >
                    <path
                        fillRule="evenodd"
                        d="M10 2a.75.75 0 01.75.75v12.5l3.97-3.97a.75.75 0 011.06 1.06l-5.5 5.5a.75.75 0 01-1.06 0l-5.5-5.5a.75.75 0 111.06-1.06L9.25 15.25V2.75A.75.75 0 0110 2z"
                    ></path>
                </svg>
            </Box>
            <Box 
                width="100%" 
                height={{ base: "auto", md: "50vh" }} 
                display="flex" 
                justifyContent="center"
            >
                <Box 
                    width={{ base: "90%", md: "75%" }} 
                    height="100%" 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="center"
                >
                    <Text
                        fontSize={{ base: "4xl", md: "6xl" }}
                        fontFamily="'Reddit Mono', monospace"
                        textColor="white"
                        bgGradient="linear(to-r, #474F7A, #81689D)"
                        bgClip="text"
                        textAlign="center"
                    >
                        <span style={{ color: "white" }}>My </span>
                        <span style={{ backgroundImage: "linear-gradient(to right, #474F7A, #81689D)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", color: "lightblue" }}>Skills</span>
                    </Text>
                    <Divider width="100%" marginTop="2rem" marginBottom="2rem" />
                    <Box 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center" 
                        flexWrap="wrap"
                    >
                        {["Web Development", "Cybersecurity", "DevOps", "Video Game Development", "C/C++", "Python", "JavaScript", "React", "Node.js"].map(skill => (
                            <Button
                                key={skill}
                                colorScheme="purple"
                                size="lg"
                                variant="outline"
                                margin="1rem"
                            >
                                {skill}
                            </Button>
                        ))}
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    );
}

export default App;
