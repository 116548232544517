import * as React from "react";
import { Box, Button, Text, Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';

function Topbar() {
    const navigate = useNavigate();

    return (
        <Box
            width="100%"
            top={0}
            left={0}
            zIndex={1}
            padding={{ base: "1rem", md: "1.5rem" }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX={{ base: "1rem", md: "3rem" }}
            bg="transparent"
        >
            <Text
                fontSize={{ base: "xl", md: "2xl" }}
                fontFamily="'Reddit Mono', monospace"
                textColor="white"
                cursor="pointer"
                onClick={() => navigate('/')}
            >
                Léo Baldachino
            </Text>
            <Box display={{ base: "none", md: "flex" }}>
                <Button
                    mr={2}
                    bg="transparent"
                    color="white"
                    fontFamily="'Reddit Mono', monospace"
                    _hover={{ color: "#474F7A" }}
                >
                    About
                </Button>
                <Button
                    mr={2}
                    bg="transparent"
                    color="white"
                    fontFamily="'Reddit Mono', monospace"
                    _hover={{ color: "#474F7A" }}
                    onClick={() => navigate('/projects')}
                >
                    Projects
                </Button>
                <Button
                    mr={2}
                    bg="transparent"
                    color="white"
                    fontFamily="'Reddit Mono', monospace"
                    _hover={{ color: "#474F7A" }}
                    onClick={() => navigate('/work')}
                >
                    Work
                </Button>
                <Button
                    mr={2}
                    bg="transparent"
                    color="white"
                    fontFamily="'Reddit Mono', monospace"
                    _hover={{ color: "#474F7A" }}
                    onClick={() => navigate('/showcase')}
                >
                    Showcase
                </Button>
                <Button
                    ml={2}
                    bgGradient="linear(to-r, #474F7A, #81689D)"
                    color="white"
                    fontFamily="'Reddit Mono', monospace"
                    _hover={{ bgGradient: "linear(to-l, #474F7A, #81689D)" }}
                >
                    Contact
                </Button>
            </Box>
            <Box display={{ base: "flex", md: "none" }}>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<HamburgerIcon />}
                        variant="outline"
                        color="white"
                        _hover={{ bg: "transparent", color: "#474F7A" }}
                        _active={{ bg: "transparent" }}
                    />
                    <MenuList>
                        <MenuItem onClick={() => navigate('/')}>About</MenuItem>
                        <MenuItem onClick={() => navigate('/projects')}>Projects</MenuItem>
                        <MenuItem onClick={() => navigate('/work')}>Work</MenuItem>
                        <MenuItem onClick={() => navigate('/showcase')}>Showcase</MenuItem>
                        <MenuItem>Contact</MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Box>
    );
}

export default Topbar;
