import * as React from "react";
import { Box, Text, Link } from "@chakra-ui/react";

function Footer() {
    return (
        <Box
            as="footer"
            width="100%"
            padding="2rem"
            backgroundColor="#1A1B2E"
            color="#999999"
            textAlign="center"
            bottom="0"
        >
            <Text fontSize="lg" fontFamily="'Reddit Mono', monospace">
                © {new Date().getFullYear()} Léo Baldachino. All rights reserved.
            </Text>
            <Box marginTop="1rem">
                <Link href="https://github.com/LeoBaldachino" isExternal margin="0 1rem" color="lightblue">
                    GitHub
                </Link>
                <Link href="https://www.linkedin.com/in/l%C3%A9o-baldachino/" isExternal margin="0 1rem" color="lightblue">
                    LinkedIn
                </Link>
                <Link href="mailto:leo.baldachino@epitech.eu" margin="0 1rem" color="lightblue">
                    Email
                </Link>
            </Box>
        </Box>
    );
}

export default Footer;