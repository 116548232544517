import * as React from "react";
import { Avatar, Box, Button, Divider, Heading, Link, Text, Image, useMediaQuery } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Background from "../components/background";
import Topbar from "../components/topbar";
import Footer from "../components/footer";

function Projects() {
    const projects = [
        {
            title: "Nuclear Edocs",
            description: "A dashboard I created during my internship in Emerson in React. It allows the differents teams from France, Deutschland and China to access the documents they need in one place. It also allows workflows creation to streamline the process of creating Nuclear documents. I got also in-charge of handling every technical aspects of this project, such as deployment, monitoring, fron-end & backend, domain-name handling, networking etc...",
            images: [
                "nuclearedocs1.png",
                "nuclearedocs2.png",
                "nuclearedocs3.png"
            ],
            date: "February 2024 - June 2024"
        },
        {
            title: "Soul Reborn",
            description: "A personnal project, a game I made in Roblox studio as part of the head of the development team. It is a Roguelike turn-per-turn roleplay game, where the player has to fight against the different monsters that are in the game, with a life system and a lot of different classes to choose from.",
            link: "https://www.roblox.com/games/16560132928/Soul-Reborn",
            date: "April 2024 - Present"
        },
        {
            title: 'Soundcloud RPC',
            description: "Personnal project, I like Soundcloud alot, and I love its diversity and community, a communication tool I use daily called Discord allow other users to know what you're doing, but not on Soundcloud, so I made my own live implementation of Soundcloud to share my activity with everyone.",
            images: [
                "https://raw.githubusercontent.com/LeoBaldachino/Soundcloud-RPC/main/images/soundcloud.png"
            ],
            date: "December 2023",
            link: "https://github.com/LeoBaldachino/Soundcloud-RPC"
        },
        {
            title: "Dexspyder",
            description: "A personnal side project I made by the time I was into cryptocurrencies. It is a tool made in NodeJS that got commercialised. It allowed its users to get in real-time insights on the latest coins releasing on the Solana blockchain, allowing them to be the first buyers on them and to profit from the price fluctuations instantly. We also offered solutions to public investment groups to help them in their decision-making process by providing them a custom-made monitor, noticing them in real-time of those new currencies on Solana blockchain.",
            images: [
                "dexspyder1.png",
                "dexspyder2.png",
                "dexspyder3.png"
            ],
            link: "https://dexspyder.io/",
            date: "January 2023 - May 2023"
        },
        {
            title: "Oozlid Studio",
            description: "A studio I started during 2023 with 2 friends from Epitech. I was handling the front-end and the system administration of the studio. It was a studio with a community-first aspect, creating websites for associations and companies in Mulhouse. It was a fun experience to work in a team and to learn a lot of new things about life and social networking.",
            images: [
                "oozlid1.png",
            ],
            date: "March 2023 - August 2023"
        },
        {
            title: "My portfolio",
            description: "A simple portfolio I made to showcase my work and my skills. It is a website made in React. It is a work in progress, but its a good start to understand my process of development.",
            date: "W.I.P"
        }
    ];

    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

    return (
        <Box style={{ width: "100%", minHeight: "100vh" }}>
            <Background />
            <Topbar />
            <Box style={{ width: "100%", padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Heading as="h1" size="2xl" fontFamily="'Reddit Mono', monospace" textAlign="center" bgGradient="linear(to-r, #474F7A, #81689D)" bgClip="text" marginBottom="2rem">
                    My Projects
                </Heading>
                {projects.map((project, index) => (
                    <Box key={index} style={{ width: "100%", maxWidth: "800px", marginBottom: "2rem", padding: isLargerThan800 ? "2rem" : "1rem", backgroundColor: "#2A2B4E", borderRadius: "8px", textAlign: "center" }}>
                        <Heading as="h2" size="xl" fontFamily="'Reddit Mono', monospace" bgGradient="linear(to-r, #474F7A, #81689D)" bgClip="text" marginBottom="0.5rem">
                            {project.title}
                        </Heading>
                        <Text fontSize="md" fontFamily="'Reddit Mono', monospace" color="gray.400" marginBottom="0.5rem">
                            {project.date}
                        </Text>
                        <Box marginBottom="1rem">
                            {project.images && project.images.length > 0 && (
                                <Carousel showThumbs={false} infiniteLoop useKeyboardArrows dynamicHeight>
                                    {project.images.map((image, imgIndex) => (
                                        <div key={imgIndex}>
                                            <Image src={image} alt={`${project.title} image ${imgIndex + 1}`} objectFit="contain" />
                                        </div>
                                    ))}
                                </Carousel>
                            )}
                        </Box>
                        <Text fontSize="lg" fontFamily="'Reddit Mono', monospace" color="white" marginBottom="0.5rem">
                            {project.description}
                        </Text>
                        {project.link && (
                            <Link href={project.link} isExternal color="lightblue">
                                View Project
                            </Link>
                        )}
                    </Box>
                ))}
            </Box>
            <Footer />
        </Box>
    );
}

export default Projects;
