import * as React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import Projects from "./pages/Projects"; // Example of another page component
import WorkHistory from "./pages/Work";
import Showcases from "./pages/Showcases";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/work" element={<WorkHistory />} />
          <Route path="/showcase" element={<Showcases />} />
        </Routes>
      </Router>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);