import * as React from "react";
import { Avatar, Box, Button, Divider, Heading, Link, Text, Image, useMediaQuery } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Background from "../components/background";
import Topbar from "../components/topbar";
import Footer from "../components/footer";

function Showcases() {
    const projects = [
        {
            title: "Fractals",
            description: "A 3D render engine I made using a library that allows GUI manipulation as framebuffers. It is entirely pixel-based and made possible by the use of custom Raycasting, camera entirely in Lua.",
            media: [
                { src: "fractal1.mp4", type: "video" },
            ],
        },
        {
            title: "Gui procedural effects & animations",
            description: "GUI Animations & Procedural effects, made using frames manipulations & colors, no textures.",
            media: [
                { src: "procedural (1).mp4", type: "video" },
                { src: "procedural (2).mp4", type: "video" },
                { src: "procedural (3).mp4", type: "video" },
                { src: "procedural (4).mp4", type: "video" },
                { src: "procedural (5).mp4", type: "video" },
            ],
        },
        {
            title: "Travel cinematic module",
            description: "A easily configurable travel / cinematic camera effect, coming with transitions between two travels.",
            media: [
                { src: "travelcinematic.mp4", type: "video" },
            ],
        },
        {
            title: "Building Utilities",
            description: "A configurable & modulable tweening script, allowing builders to give parts effects like orbiting around a point, hovering, rotating, or all of them at once.",
            media: [
                { src: "tweenmovements.mp4", type: "video" },
            ],
        },
        {
            title: "Stat spensing system",
            description: "A stat spensing system, where the player can spend the stats points they have into the stats they want, disallowing regression between stats.",
            media: [
                { src: "train.mp4", type: "video" },
            ],
        },
        {
            title: "NPC / Dialogue system / Notifications",
            description: "A NPC / Dialogue system, where the player can interact with NPCs and have conversations with them, and the NPCs can have multiple paths / dialogues according to conditions, with scriptable behaviors & results. There is also a notification system with customisable title, description and sound for each notification.",
            media: [
                { src: "npc.mp4", type: "video" },
            ],
        }
    ];

    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

    return (
        <Box style={{ width: "100%", minHeight: "100vh" }}>
            <Background />
            <Topbar />
            <Box style={{ width: "100%", padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Heading as="h1" size="2xl" fontFamily="'Reddit Mono', monospace" textAlign="center" bgGradient="linear(to-r, #474F7A, #81689D)" bgClip="text" marginBottom="2rem">
                    My Showcases
                </Heading>
                <Text fontSize="lg" fontFamily="'Reddit Mono', monospace" color="white" marginBottom="0.5rem">
                    Those are some showcases of systems I have made on the Roblox game engine.
                </Text>
                {projects.map((project, index) => (
                    <Box key={index} style={{ width: "100%", maxWidth: "800px", marginBottom: "2rem", padding: isLargerThan800 ? "2rem" : "1rem", backgroundColor: "#2A2B4E", borderRadius: "8px", textAlign: "center" }}>
                        <Heading as="h2" size="xl" fontFamily="'Reddit Mono', monospace" bgGradient="linear(to-r, #474F7A, #81689D)" bgClip="text" marginBottom="0.5rem">
                            {project.title}
                        </Heading>
                        <Text fontSize="md" fontFamily="'Reddit Mono', monospace" color="gray.400" marginBottom="0.5rem">
                            {project.date}
                        </Text>
                        <Box marginBottom="1rem">
                            {project.media && project.media.length > 0 && (
                                <Carousel showThumbs={false} infiniteLoop useKeyboardArrows dynamicHeight>
                                    {project.media.map((item, mediaIndex) => (
                                        <div key={mediaIndex}>
                                            {item.type === "image" ? (
                                                <Image src={item.src} alt={`${project.title} media ${mediaIndex + 1}`} objectFit="contain" />
                                            ) : (
                                                <Box as="video" src={item.src} width="100%" height="400px" controls />
                                            )}
                                        </div>
                                    ))}
                                </Carousel>
                            )}
                        </Box>
                        <Text fontSize="lg" fontFamily="'Reddit Mono', monospace" color="white" marginBottom="0.5rem">
                            {project.description}
                        </Text>
                        {project.link && (
                            <Link href={project.link} isExternal color="lightblue">
                                View Project
                            </Link>
                        )}
                    </Box>
                ))}
            </Box>
            <Footer />
        </Box>
    );
}

export default Showcases;
