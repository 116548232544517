import * as THREE from 'three';
import React, { useEffect } from 'react';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

const dummy = new THREE.Object3D();

function Background() {
    useEffect(() => {
        const canvas = document.getElementById('background');
        const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0x0D1222).multiplyScalar(0.3);
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 10000);
        camera.position.z = 0;
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        const material = new THREE.MeshBasicMaterial({ color: 0x81689D });

        // Reduce the number of spheres
        const sphereCountX = 10; // Reduced number of spheres along the X-axis
        const sphereCountY = 10; // Reduced number of spheres along the Y-axis
        const separation = 10;
        const amplitude = 5;
        const geometry = new THREE.SphereGeometry(0.1, 8, 8); // Reduced detail level
        const instancedMesh = new THREE.InstancedMesh(geometry, material, sphereCountX * sphereCountY);
        let id = 0;
        const dummy = new THREE.Object3D();
        for (let i = 0; i < sphereCountX; i++) {
            for (let j = 0; j < sphereCountY; j++) {
                dummy.position.set(
                    separation * (i - sphereCountX / 2),
                    0,
                    separation * (j - sphereCountY / 2)
                );
                dummy.updateMatrix();
                instancedMesh.setMatrixAt(id++, dummy.matrix);
            }
        }
        scene.add(instancedMesh);

        const composer = new EffectComposer(renderer);
        composer.addPass(new RenderPass(scene, camera));
        const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.0, 0.4, 0.85); // Adjusted bloom effect
        bloomPass.threshold = 0.1;
        bloomPass.strength = 1.0; // Reduced intensity of glow
        bloomPass.radius = 0;
        composer.addPass(bloomPass);

        function resizeRendererToDisplaySize(renderer) {
            const canvas = renderer.domElement;
            const width = canvas.clientWidth;
            const height = canvas.clientHeight;
            const needResize = canvas.width !== width || canvas.height !== height;
            if (needResize) {
                renderer.setSize(width, height, false);
            }
            return needResize;
        }

        function render(time) {
            time *= 0.0005;
            for (let i = 0; i < sphereCountX; i++) {
                for (let j = 0; j < sphereCountY; j++) {
                    const yOffset = Math.sin(time + i * 0.5 + j * 0.5) * amplitude;
                    dummy.position.set(
                        separation * (i - sphereCountX / 2),
                        yOffset,
                        separation * (j - sphereCountY / 2)
                    );
                    dummy.updateMatrix();
                    instancedMesh.setMatrixAt(i * sphereCountY + j, dummy.matrix);
                }
            }

            instancedMesh.instanceMatrix.needsUpdate = true;

            camera.position.x = Math.sin(time) * 50;
            camera.position.z = Math.cos(time) * 50;
            camera.position.y = 20;
            camera.lookAt(0, 0, 0);

            if (resizeRendererToDisplaySize(renderer)) {
                const canvas = renderer.domElement;
                camera.aspect = canvas.clientWidth / canvas.clientHeight;
                camera.updateProjectionMatrix();
                composer.setSize(canvas.clientWidth, canvas.clientHeight);
            }

            composer.render();
            requestAnimationFrame(render);
        }

        requestAnimationFrame(render);
    }, []); // <-- Close useEffect here

    return (
        <canvas id="background" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} />
    );
}

export default Background;