import * as React from "react";
import { Box, Heading, Text, Image, VStack, HStack, Divider, Tag, Wrap, useMediaQuery } from "@chakra-ui/react";
import Background from "../components/background";
import Topbar from "../components/topbar";
import Footer from "../components/footer";

function WorkHistory() {
    const workHistory = [
        {
            title: "Full-stack Developer",
            company: "Emerson",
            description: "Developed and maintained a fully-featured dashboard allowing the differents teams from France, Deutschland and China to access the documents they need in one place. It also allows workflows creation to streamline the process of creating Nuclear documents. I got also in-charge of handling every technical aspects of this project, such as deployment, monitoring, fron-end & backend, domain-name handling, networking etc...",
            dates: "Feb 2024 - Jun 2024",
            image: "emerson_logo.jpg",
            skills: ["React", "Node.js", "Docker", "System-Administration", "CI/CD", "Networking", "Azure", "Notion"]
        },
        {
            title: "Software Engineer",
            company: "Staff&Go",
            description: "Implemented AI to automate the creating of new employees contracts for Staff&Go customers.",
            dates: "Oct 2023 - Jan 2024",
            image: "staffngo.png",
            skills: ["C#", "Python", "OpenAI", "Docker", "Kubernetes"]
        },
        {
            title: "Full-stack Developer",
            company: "Alphéa net",
            description: "Developed and maintained a web dashboard, allowing optimised quotes creating and management for both customers and Alphéa net team, allowing PDF generation, mailing, and payment processing.",
            dates: "Mar 2016 - Jan 2018",
            image: "alphea.jpg",
            skills: ["JavaScript", "Node.js", "MySQL", "Stripe", "PDF"]
        }
    ];

    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

    return (
        <Box style={{ width: "100%", minHeight: "100vh" }}>
            <Background />
            <Topbar />
            <Box style={{ width: "100%", padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Heading as="h1" size="2xl" fontFamily="'Reddit Mono', monospace" textAlign="center" bgGradient="linear(to-r, #474F7A, #81689D)" bgClip="text" marginBottom="2rem">
                    Work History
                </Heading>
                <VStack spacing={8} width="100%" maxWidth="800px">
                    {workHistory.map((job, index) => (
                        <Box key={index} width="100%" position="relative">
                            <HStack spacing={4} alignItems="flex-start" width="100%" flexDirection={isLargerThan800 ? "row" : "column"}>
                                <Image boxSize="150px" objectFit="cover" src={job.image} alt={job.title} borderRadius="8px" />
                                <VStack alignItems="flex-start" spacing={2} width="100%">
                                    <Heading as="h2" size="lg" fontFamily="'Reddit Mono', monospace" bgGradient="linear(to-r, #474F7A, #81689D)" bgClip="text">
                                        {job.title}
                                    </Heading>
                                    <Text fontSize="md" fontFamily="'Reddit Mono', monospace" color="lightblue">
                                        {job.company}
                                    </Text>
                                    <Text fontSize="sm" fontFamily="'Reddit Mono', monospace" color="white">
                                        {job.description}
                                    </Text>
                                    <Text fontSize="sm" fontFamily="'Reddit Mono', monospace" color="gray.500">
                                        {job.dates}
                                    </Text>
                                    <Wrap>
                                        {job.skills.map((skill, skillIndex) => (
                                            <Tag key={skillIndex} colorScheme="purple" fontFamily="'Reddit Mono', monospace">
                                                {skill}
                                            </Tag>
                                        ))}
                                    </Wrap>
                                </VStack>
                            </HStack>
                            {index < workHistory.length - 1 && (
                                <Divider orientation="horizontal" borderColor="#474F7A" marginY={4} />
                            )}
                        </Box>
                    ))}
                </VStack>
            </Box>
            <Footer />
        </Box>
    );
}

export default WorkHistory;
